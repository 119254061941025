
.card-gradient{
    --box-rounded: 16px;
    position: relative;
    overflow: hidden;
    border-left: 1px solid var(--v-primary-base) !important;
    &::after{
        content: "";
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        width: var(--box-rounded);
        height: var(--box-rounded);
        background-image: url(/images/common/box-rounded-sm.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    &::before{
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: calc(100% - var(--box-rounded));
        background-color: var(--v-primary-base);
        top: 0;
        right: 0;
    }
    &__line{
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        border-radius: 0 !important;
        background: linear-gradient(to left, #B18A00 0%, #FFEA9F 50%, #B18A00 100%);
        &--1{
            top: 0;
        }
        &--2{
            bottom: 0;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .card-gradient{
        --box-rounded: 24px;
        &::after{
            background-image: url(/images/common/box-rounded-md.svg);
        }
    }
}
@media (min-width:1024px){
    .card-gradient{
        --box-rounded: 40px;
        &::after{
            background-image: url(/images/common/box-rounded.svg);
        }
    }
}
@media (min-width:1200px){
}
